import React, { useEffect } from "react";
import { Shaders, Node, GLSL } from "gl-react";
import MersenneTwister from "mersenne-twister";

/*
Create your Custom style to be turned into a EthBlock.art Mother NFT

Basic rules:
 - use a minimum of 1 and a maximum of 4 "modifiers", modifiers are values between 0 and 1,
 - use a minimum of 1 and a maximum of 3 colors, the color "background" will be set at the canvas root
 - Use the block as source of entropy, no Math.random() allowed!
 - You can use a "shuffle bag" using data from the block as seed, a MersenneTwister library is provided

 Arguments:
  - block: the blockData, in this example template you are given 3 different blocks to experiment with variations, check App.js to learn more
  - mod[1-3]: template modifier arguments with arbitrary defaults to get your started
  - color: template color argument with arbitrary default to get you started

Getting started:
 - Write gl-react code, comsuming the block data and modifier arguments,
   make it cool and use no random() internally, component must be pure, output deterministic
 - Customize the list of arguments as you wish, given the rules listed below
 - Provide a set of initial /default values for the implemented arguments, your preset.
 - Think about easter eggs / rare attributes, display something different every 100 blocks? display something unique with 1% chance?

 - check out https://gl-react-cookbook.surge.sh/ for examples!
*/

function map (value, min1, max1, min2, max2) {
  return min2 + (value - min1) * (max2 - min2) / (max1 - min1)
}

export const styleMetadata = {
  name: "Helios Ephemeris",
  description: "",
  image: "",
  creator_name: "Nèr Arfer",
  options: {
    modTime: 0.5,
    modLatitude: map(43.0382, -90, 90, 0, 1),
    modSize: 0.7,
    modTurbidity: 0.7,
    modPedestal: 1.0,
    modContrast: 0.1,
    modLinSecStart: 0.5,
    modLinSecLength: 0.5,
    modBlack: 0.5,
    modNoise: 0.1,
    // mod5: 0.5
  },
};

const shaders = Shaders.create({
  main: {
    frag: GLSL`

precision highp float;
varying vec2 uv;

uniform float modTurbidity; // turbidity
uniform float modSize; // size
uniform float modPedestal; // pedestal
uniform float modContrast; // contrast
uniform float modLatitude; // latitude
uniform float modLinSecStart;
uniform float modLinSecLength;
uniform float modBlack;
uniform float modNoise;
uniform float mod5;
uniform float unix;
uniform float seed;

const float PI = 3.1415926535897932384626433832795;

float rand (vec2 st) {
  return fract(sin(dot(st.xy, vec2(12.9898,78.233)))* 43758.5453123);
}

float Perez (float zenith, float gamma, float A, float B, float C, float D, float E) {
  return (1.0 + A * exp(B / cos(zenith))) * (1.0 + C * exp(D * gamma) + E * pow(cos(gamma), 2.0));
}

float Gamma (float zenith, float azimuth, float sz, float sa) {
  return acos(sin(sz)*sin(zenith)*cos(azimuth-sa)+cos(sz)*cos(zenith));
}

vec4 RGB (float Y, float x, float y) {
  float X = x/y*Y;
  float Z = (1.0-x-y)/y*Y;
  vec4 rgb;
  rgb.a = 1.0;
  rgb.r =  3.2406 * X - 1.5372 * Y - 0.4986 * Z;
  rgb.g = -0.9689 * X + 1.8758 * Y + 0.0415 * Z;
  rgb.b =  0.0557 * X - 0.2040 * Y + 1.0570 * Z;
  return rgb;
}

float map(float value, float min1, float max1, float min2, float max2) {
  return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
}

vec2 project (vec2 pos) {
  return vec2(
    atan((pos.y - 0.5), (pos.x - 0.5)) * 1.0,
    sqrt(pow(pos.y - 0.5, 2.0) + pow(pos.x - 0.5, 2.0)) * PI
  );
}

vec3 tonemap (vec3 x) {
  float P = 1.0 + modSize / 2.0;  // max display brightness
  float a = 1.0 + modContrast * 3.0;  // contrast
  float m = modLinSecStart * 2.0; // linear section start
  float l = modLinSecLength * 2.0;  // linear section length
  float c = modBlack * 4.0; // black
  float b = 1.0 - modPedestal;  // pedestal

  float l0 = ((P - m) * l) / a;
  float L0 = m - m / a;
  float L1 = m + (1.0 - m) / a;
  float S0 = m + l0;
  float S1 = m + a * l0;
  float C2 = (a * P) / (P - S1);
  float CP = -C2 / P;

  vec3 w0 = vec3(1.0 - smoothstep(0.0, m, x));
  vec3 w2 = vec3(step(m + l0, x));
  vec3 w1 = vec3(1.0 - w0 - w2);

  vec3 T = vec3(m * pow(x / m, vec3(c)) + b);
  vec3 S = vec3(P - (P - S1) * exp(CP * (x - S0)));
  vec3 L = vec3(m + a * (x - m));

  return T * w0 + L * w1 + S * w2;
  // return x;
}

vec2 blur (vec2 pos) {
  return vec2(
    pos.x + (rand(pos) - 0.5) * modNoise * 0.25,
    pos.y + (rand(pos * 2.0) - 0.5) * modNoise * 0.25
  );
}

void main () {
  float Turbidity = map(modTurbidity, 0.0, 1.0, 5.0, 15.0);

  float seconds = unix;

  float julian = (seconds / 86400.0) + 2440587.5;
  float dayhours = (seconds / 86400.0 - floor(seconds / 86400.0)) * 24.0;

  float solarTime = PI / 12.0 * (dayhours - 12.0 + 0.17 * sin(4.0 * PI * (julian - 80.0) / 373.0) - 0.129 * sin(2.0 * PI * (julian - 8.0) / 355.0));

  float declination = 0.4093 * sin(2.0 * PI * (julian - 81.0) / 368.0);
  float zenith = acos(sin(modLatitude) * sin(declination) + cos(modLatitude) * cos(declination) * cos(solarTime));
  float azimuth = solarTime;
  float SolarZenith = zenith; //  / 2.0;

  float Yz = ((4.0453 * Turbidity - 4.9710) * tan((4.0 / 9.0 - Turbidity / 120.0) * (PI - 2.0 * SolarZenith)) - 0.2155 * Turbidity + 2.4192) / (4.0453 * Turbidity - 4.9710) * tan((4.0 / 9.0 - Turbidity / 120.0) * (PI)) - 0.2155 * Turbidity + 2.4192;

  float z3 = pow(SolarZenith, 3.0);
  float z2 = SolarZenith * SolarZenith;
  float sz = SolarZenith;
  vec3 T_vec = vec3(Turbidity * Turbidity, Turbidity, 1.0);

  vec3 x = vec3(0.00166 * z3 - 0.00375 * z2 + 0.00209 * sz, -0.02903 * z3 + 0.06377 * z2 - 0.03202 * sz + 0.00394, 0.11693 * z3 - 0.21196 * z2 + 0.06052 * sz + 0.25886);
  float xz = dot(T_vec, x);

  vec3 y = vec3(0.00275 * z3 - 0.00610 * z2 + 0.00317 * sz, -0.04214 * z3 + 0.08970 * z2 - 0.04153 * sz + 0.00516, 0.15346 * z3 - 0.26756 * z2 + 0.06670 * sz + 0.26688);
  float yz = dot(T_vec, y);

  float YA = 0.1787 * Turbidity - 1.4630;
  float YB = -0.3554 * Turbidity + 0.4275;
  float YC = -0.0227 * Turbidity + 5.3251;
  float YD = 0.1206 * Turbidity - 2.5771;
  float YE = -0.0670 * Turbidity + 0.3703;

  float xA = -0.0193 * Turbidity - 0.2592;
  float xB = -0.0665 * Turbidity + 0.0008;
  float xC = -0.0004 * Turbidity + 0.2125;
  float xD = -0.0641 * Turbidity - 0.8989;
  float xE = -0.0033 * Turbidity + 0.0452;

  float yA = -0.0167 * Turbidity - 0.2608;
  float yB = -0.0950 * Turbidity + 0.0092;
  float yC = -0.0079 * Turbidity + 0.2102;
  float yD = -0.0441 * Turbidity - 1.6537;
  float yE = -0.0109 * Turbidity + 0.0529;

  vec2 UV = project(blur(vec2(
    uv.y,
    1.0 - uv.x
  )));

  float a = UV.x;
  float z = min(pow(UV.y, 1.0) / 2.0 / modSize, PI / 2.0);

  float gamma = Gamma(z, a, zenith, azimuth);
  z = min(z, 3.1415926 / 2.0);
  float Yp = Yz * Perez(z, gamma, YA, YB, YC, YD, YE) / Perez(0.0, zenith, YA, YB, YC, YD, YE);
  float xp = xz * Perez(z, gamma, xA, xB, xC, xD, xE) / Perez(0.0, zenith, xA, xB, xC, xD, xE);
  float yp = yz * Perez(z, gamma, yA, yB, yC, yD, yE) / Perez(0.0, zenith, yA, yB, yC, yD, yE);

  gl_FragColor = vec4(tonemap(RGB(Yp, xp, yp).rgb), 1.0);
}

  `,
  },
});

const CustomStyle = ({ block, attributesRef, width, height, modTurbidity, modSize, modPedestal, modTime, modContrast, modLatitude, modLinSecStart, modLinSecLength, modBlack, modNoise, mod5 }) => {
  useAttributes(attributesRef);

  const { hash } = block;

  const rng = new MersenneTwister(parseInt(hash.slice(0, 16), 16));

  const date = new Date(block.timestamp * 1000 + map(modTime, 0, 1, -12 * 60 * 60 * 1000, 12 * 60 * 60 * 1000)) // block.timestamp * 1000
  document.querySelector('h3').textContent = `Helios Ephemeris / ${new Date(date.getTime()).toLocaleString('fr')}`

  return (
    <Node
      shader={shaders.main}
      uniforms={{
        modTurbidity,
        modSize,
        modPedestal,
        modTime,
        modContrast,
        modLatitude: map(modLatitude, 0, 1, -Math.PI / 2, Math.PI / 2),
        modLinSecStart, modLinSecLength, modBlack, modNoise, mod5,
        unix: date.getTime() / 1000,
        seed: rng.random(),
      }}
    />
  );
};

function useAttributes(ref) {
  // Update custom attributes related to style when the modifiers change
  useEffect(() => {
    ref.current = () => {
      return {
        // This is called when the final image is generated, when creator opens the Mint NFT modal.
        // should return an object structured following opensea/enjin metadata spec for attributes/properties
        // https://docs.opensea.io/docs/metadata-standards
        // https://github.com/ethereum/EIPs/blob/master/EIPS/eip-1155.md#erc-1155-metadata-uri-json-schema

        attributes: [
          {
            trait_type: "your trait here text",
            value: "replace me",
          },
        ],
      };
    };
  }, [ref]);
}

export default CustomStyle;
